'use client';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IoIosSearch, IoIosMenu, IoIosClose } from 'react-icons/io';
import headerData from '../../common/headerData';
import PFLink from '../PFLink';
import { useRouter, useSearchParams } from 'next/navigation';
import interactionResponse from 'await-interaction-response';
import { Carousel, CarouselItem } from '../Carousel';
import { PFText } from '../PFText';
import { classNames } from '@/util/classNames';
import { NotoficationBellButtonClientWrapper } from '../NotoficationBellButtonClientWrapper';

let lastYshow = 0;
let lastY = 0;

function Header() {
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const firstRunRef = useRef(true);
  useEffect(() => {
    if (!firstRunRef.current) {
      window.scrollTo(0, 0);
    }

    firstRunRef.current = false;
  }, [showBurgerMenu]);

  // TODO: remove feature & make navbar responsive
  const newNavEnabled = true;
  const [show, setShow] = useState(true);

  const controlNavbar = useCallback(() => {
    //window.scrollY > 0 fix for safari, because you can overscroll
    if (window.scrollY > 0) {
      if (lastYshow + 10 < window.scrollY && show) {
        setShow(false);
      } else if (lastY > window.scrollY && !show) {
        setShow(true);
        lastYshow = window.scrollY;
      }
      if (show && lastY > window.scrollY) {
        lastYshow = window.scrollY;
      }
    }
    if (!show && window.scrollY === 0) {
      setShow(true);
      lastYshow = window.scrollY;
    }
    lastY = window.scrollY;
  }, [show]);

  useEffect(() => {
    if (!newNavEnabled) return;
    window.addEventListener('scroll', controlNavbar);
    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }, [controlNavbar, newNavEnabled]);

  const router = useRouter();
  const { highlightedLinks, burgerMenuTopics } = headerData;

  return (
    <header
      className={classNames(
        'main-header sm:min-h-0  sm:rounded-t-xl overflow-hidden',
        newNavEnabled ? 'min-h-[90px]' : 'min-h-[50px]',
      )}
    >
      <div className="min-h-[50px] bg-tintColor sm:bg-white sm:dark:bg-darkGrey">
        <div className="fixed top-[74] left-0 right-0 z-[10000] sm:static sm:z-auto">
          {newNavEnabled && (
            <div
              className={classNames(
                'sm:hidden absolute top-[50px] w-full overflow-hidden transition-height duration-500',
                !show && 'h-0',
                show && 'h-[41px]',
              )}
            >
              <div
                className={classNames(
                  'border-solid border-b-[0.5px] border-lightGrey bg-white dark:bg-darkGrey sm:hidden transition-all duration-500 transform',
                  show ? 'translate-y-0' : '-translate-y-full',
                )}
              >
                <Carousel
                  hideArrows
                  disableSnap
                  className="last:pr-base first:pl-base py-2 min-h-[40px] no-scrollbar"
                >
                  {burgerMenuTopics.map(({ name, slug }) => (
                    <CarouselItem key={slug} width={'fit-content'}>
                      <PFLink
                        href={`/thema/${slug}/`}
                        key={`fact-${slug}`}
                        className="text-darkGrey dark:text-white group w-full"
                        fakeLink
                      >
                        <PFText
                          className="group-hover:text-secundaryColor whitespace-nowrap text-start"
                          size="baseDesktop"
                        >
                          {name}
                        </PFText>
                      </PFLink>
                    </CarouselItem>
                  ))}
                </Carousel>
              </div>
            </div>
          )}
          <div className="flex flex-1 h-[50px] sm:h-[64px] text-white bg-tintColor/80 sm:bg-tintColor backdrop-blur sm:backdrop-blur-none sm:rounded-t-xl">
            <button
              title={
                showBurgerMenu ? 'Navigation verstecken' : 'Navigation anzeigen'
              }
              className="block p-[3px] ml-[14px] sm:p-[10px] cursor-pointer self-center"
              onClick={(event) => {
                event.preventDefault();
                setShowBurgerMenu((prev) => !prev);

                interactionResponse().then(() => {
                  window.dataLayer?.push({
                    event: 'custom_event',
                    event_name: showBurgerMenu
                      ? 'sidemenu_fold'
                      : 'sidemenu_unfold',
                  });
                });
              }}
            >
              {!showBurgerMenu ? (
                <IoIosMenu
                  className="block cursor-pointer text-white hover:text-tintColorHover"
                  size={36}
                />
              ) : (
                <IoIosClose
                  className="block cursor-pointer text-white hover:text-tintColorHover"
                  size={36}
                />
              )}
            </button>
            <div className="w-9" />
            <PFLink
              href="/"
              className="flex flex-1 justify-center items-center"
              title="Startseite"
              onClick={() => {
                setShowBurgerMenu(false);
              }}
            >
              <img
                width={1200}
                height={223}
                alt="Promiflash Logo"
                src="https://content.promiflash.de/static-images/header-transparent-v1.png"
                className="w-[162px] sm:w-[217px] cursor-pointer"
              />
            </PFLink>
            <NotoficationBellButtonClientWrapper />
            <span
              className="block p-[3px] mr-[14px] sm:p-[10px] cursor-pointer self-center"
              onClick={() => router.push('/suche/')}
              title="Suche"
            >
              <IoIosSearch
                className="block cursor-pointer text-white hover:text-tintColorHover"
                size={36}
              />
            </span>
          </div>
        </div>
        <nav
          className={`flex flex-col overflow-hidden
          ${showBurgerMenu ? 'bg-white sm:pt-0 dark:bg-darkGrey' : 'h-0'}`}
        >
          <div className="relative">
            <div className="pt-[50px] bg-tintColor sm:pt-0" />
            <div>
              <ul className="flex flex-col items-center mt-5 mx-0 mb-0 pt-[40px]">
                {burgerMenuTopics.map(({ slug, name }) => (
                  <li key={'burger' + slug} className="p-[10px]">
                    <PFLink
                      className="text-grey text-[18px] hover:text-darkGrey dark:hover:text-white"
                      href={`/thema/${slug}/`}
                      onClick={() => {
                        setShowBurgerMenu(false);
                        window.dataLayer?.push({
                          event: 'custom_event',
                          event_name: 'navigation',
                          navigation: {
                            navigation_type: 'sidemenu',
                            click_item: name,
                          },
                        });
                      }}
                    >
                      {name}
                    </PFLink>
                  </li>
                ))}
              </ul>
              <ul className="flex flex-col items-center mt-5 mx-0 mb-0">
                {highlightedLinks.map(({ route, name }) => (
                  <li className="p-[10px]" key={name}>
                    <PFLink
                      className="text-[18px] hover:text-secundaryColor"
                      href={route}
                      onClick={() => {
                        setShowBurgerMenu(false);
                        window.dataLayer?.push({
                          event: 'custom_event',
                          event_name: 'navigation',
                          navigation: {
                            navigation_type: 'sidemenu',
                            click_item: name,
                          },
                        });
                      }}
                    >
                      {name}
                    </PFLink>
                  </li>
                ))}
                <li className="p-[10px]">
                  <PFLink
                    fakeLink
                    className="text-[18px] hover:text-secundaryColor"
                    href={'/push/'}
                    onClick={() => {
                      setShowBurgerMenu(false);
                      window.dataLayer?.push({
                        event: 'custom_event',
                        event_name: 'navigation',
                        navigation: {
                          navigation_type: 'sidemenu',
                          click_item: name,
                        },
                      });
                    }}
                  >
                    Push-Benachrichtigungen
                  </PFLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <nav>
        <ul className="hidden justify-between py-0 px-5 overflow-x-scroll whitespace-nowrap overflow-y-scroll sm:flex sm:overflow-hidden">
          {burgerMenuTopics.slice(0, 7).map(({ slug, name }) => (
            <li key={slug}>
              <PFLink
                href={`/thema/${slug}/`}
                onClick={() => {
                  setShowBurgerMenu(false);
                  window.dataLayer?.push({
                    event: 'custom_event',
                    event_name: 'navigation',
                    navigation: {
                      navigation_type: 'header',
                      click_item: name,
                    },
                  });
                }}
                fakeLink
                className="dark:text-white hover:text-tintColor inline-block relative no-underline text-darkGrey p-1 my-4 mx-4 text-base sm:mx-0"
              >
                {name}
              </PFLink>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
